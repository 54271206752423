/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import "~firebaseui/dist/firebaseui.css";
@import "~@angular/material/theming";

@include mat-core();

$primary: $theme-primary-palette;
$accent: $theme-accent-palette;
$warn: $theme-warn-palette;

$theme: mat-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
    ));

@include angular-material-theme($theme);

.mat-drawer {
  color: mat-color($primary);
  background-color: white !important;
}

.mat-expansion-panel-header {
  color: mat-color($primary);
  background-color: whitesmoke !important;
}

.mat-expansion-panel-body {
  color: mat-color($primary);
  background-color: whitesmoke !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: mat-color($accent);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: mat-color($accent);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.spacer {
  flex: 1 1 auto;
}

.app-container {
  min-height: calc(100vh - 112px) !important;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.mat-icon-btn-text {
  display: flex;
  align-items: center;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100% !important;
}

.mat-sidenav .mat-icon {
  color: mat-color($primary) !important;
}

.mat-expansion-panel-body {
  padding: 3px !important;
}

.row {
  margin: 0px !important;
  padding: 0px !important;
}

.row>* {
  margin: 0px !important;
  padding: 0px !important;
}

.mat-toolbar {
  height: 56px !important;
}

.primary-text {
  color: mat-color($primary) !important;
}

.primary-bgcolor {
  background-color: mat-color($primary) !important;
  color: white !important;
}

.bg-primary {
  background-color: mat-color($primary) !important;
}

.bg-accent {
  background-color: mat-color($accent) !important;
}

.bg-warn {
  background-color: mat-color($warn) !important;
}

.bg-primary-light {
  background-color: mat-color($primary, 200) !important;
}

.bg-accent-light {
  background-color: mat-color($accent, 200) !important;
}

.bg-warn-light {
  background-color: mat-color($warn, 200) !important;
}

.mheight-150 {
  min-height: 150px !important;
  max-height: 150px !important;
}

.mheight-200 {
  min-height: 200px !important;
  max-height: 200px !important;
}

.mheight-300 {
  min-height: 300px !important;
  max-height: 300px !important;
}